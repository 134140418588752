<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="kpi_korporat_id" label="Indikator Kinerja">
            <a-select
              placeholder="Pilih Indikator Kinerja"
              v-model:value="formState.kpi_korporat_id"
              show-search
              :filter-option="filterSelect"
              @change="changeKpiKorporat"
              :disabled="mode == 'Ubah' ? true : false"
            >
              <a-select-option v-for="option in options.kpiKorporat" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="month" label="Bulan">
            <a-select
              placeholder="Pilih Bulan"
              v-model:value="formState.month"
              show-search
              :filter-option="filterSelect"
              :disabled="mode == 'Ubah' ? true : false"
            >
              <a-select-option v-for="option in options.month" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <template v-for="variable in variableKpiKorporats.data" :key="variable.id">
            <a-form-item
              :name="'realisasis[' + variable.id + ']'"
              :label="'Realisasi ' + variable.name"
              v-if="variable.kpi_korporat.jenis_variable_kpi_id == 1"
            >
              <a-input-number
                v-model:value="formState.realisasis[variable.id]"
                :placeholder="'Masukkan ' + variable.name"
              />
            </a-form-item>

            <a-form-item :name="'realisasis[' + variable.id + ']'" :label="'Realisasi ' + variable.name" v-else>
              <a-month-picker
                v-model:value="formState.realisasis[variable.id]"
                :placeholder="'Pilih ' + variable.name"
                format="MM-YYYY"
                valueFormat="YYYY-MM"
              />
            </a-form-item>
          </template>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/kpi/laporan-kpi-korporat">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const RealisasiKpiKorporatForm = {
  name: 'RealisasiKpiKorporatForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();

    const formState = reactive({
      kpi_korporat_id: undefined,
      month: dateObj.getUTCMonth() + 1,
      realisasis: [],
    });

    const options = reactive({
      kpiKorporat: [],
      month: [
        { value: 1, text: 'Januari' },
        { value: 2, text: 'Februari' },
        { value: 3, text: 'Maret' },
        { value: 4, text: 'April' },
        { value: 5, text: 'Mei' },
        { value: 6, text: 'Juni' },
        { value: 7, text: 'Juli' },
        { value: 8, text: 'Agustus' },
        { value: 9, text: 'September' },
        { value: 10, text: 'Oktober' },
        { value: 11, text: 'November' },
        { value: 12, text: 'Desember' },
      ],
    });

    const variableKpiKorporats = reactive({
      data: {},
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      const postData = toRaw(formState);

      if (variableKpiKorporats.data[0].kpi_korporat.jenis_variable_kpi_id == 2) {
        postData.realisasis.forEach((realisasi, key) => {
          let my = realisasi.split('-');
          postData.realisasis[key] = my[0] + '-' + my[1] + '-' + '01';
        });
      }

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-kpi-korporat',
          data: postData,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'laporan-kpi-korporat',
          data: postData,
        });
      }
    };

    const rules = {
      kpi_korporat_id: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
      month: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const changeKpiKorporat = value => {
      dispatch('axiosCrudGetData', 'variable-kpi-korporats-by-kpi-korporat?kpi_korporat_id=' + value).then(() => {
        variableKpiKorporats.data = crud.value.data;
      });
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'options-kpi-korporat-with-formula').then(() => {
        options.kpiKorporat = crud.value;
      });

      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-kpi-korporat',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });

          let kpi_korporat_id = crud.value.kpi_korporat_id;
          let month = crud.value.month;
          let jenis_variable_kpi_id = crud.value.kpi_korporat.jenis_variable_kpi_id;

          dispatch(
            'axiosCrudGetData',
            'variable-kpi-korporats-by-kpi-korporat?kpi_korporat_id=' + crud.value.kpi_korporat_id,
          ).then(() => {
            variableKpiKorporats.data = crud.value.data;

            dispatch(
              'axiosCrudGetData',
              'realisasi-variables-by-kpi-korporat?kpi_korporat_id=' + kpi_korporat_id + '&month=' + month,
            ).then(() => {
              crud.value.data.forEach(data => {
                if (jenis_variable_kpi_id == 2) {
                  formState.realisasis[data.variable_kpi_korporat_id] = data.realisasi_waktu;
                } else {
                  formState.realisasis[data.variable_kpi_korporat_id] = data.realisasi_nominal;
                }
              });
            });
          });
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
      changeKpiKorporat,
      variableKpiKorporats,
    };
  },
};

export default RealisasiKpiKorporatForm;
</script>
